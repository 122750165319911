
:root {
  font-size: 0.85em;
  --main-font: Helvetica, Arial, sans-serif;
  --text-color: white;
  --text-color-night: black;
}
@media (min-width: 800px) {
  :root {
    font-size: 0.875em;
  }
}
@media (min-width: 1200px) {
  :root {
    font-size: 1em;
  }
}

@font-face {
  font-family: 'MedievalSharp';
  src: url('./MedievalSharp-Regular.ttf') format('truetype');
}


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  font-family: 'MedievalSharp', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
}

button {
  font-family: 'MedievalSharp', sans-serif;
  letter-spacing: 0.05rem;
}

button:disabled {
  cursor: not-allowed;
}

code {
  font-family: 'MedievalSharp', sans-serif;
}

.number_font{
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}
