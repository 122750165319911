.pointer {
  cursor: pointer;
}

.button {
  cursor: pointer;
  border: solid;
  border-color: black;
  border-width: 2px;
  background-color: white;
  width: fit-content;
  padding: 1px 6px;

  &:hover {
    background-color: rgb(224, 224, 224);
  }
}
