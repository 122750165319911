@keyframes fadeInOut {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.navbar {
  display: flex;
  align-items: center;
  height: 60px;
  color: #fff;
  padding: 20px 0px;
  justify-content: space-evenly;
  flex-wrap: wrap;

  &__items {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: space-evenly;
    width: 100%;
  }
  &__item {
    padding: 15px 35px;
    border-radius: 10px;
    background-color: rgba($color: #000000, $alpha: 1);
    border: solid;
    border-color: grey;
    border-width: 2px;
    cursor: pointer;

    &:hover {
      color: #ccc;
      border-color: rgb(57 208 184);
      background-color: rgba($color: #000000, $alpha: 0.85);
      transition: color 0.15s linear;
      transition: background-color 0.15s linear;
      transition: border-color 0.15s linear;
    }
  }
  &__link {
    color: #fff;
    text-decoration: none;
    font-size: 14px;
  }
  &__username {
    font-weight: bolder;
  }
}

.game_type {
  padding: 10px 0px;
  cursor: pointer;
}

.game_type_border {
  cursor: pointer;
  border-bottom: 1px solid lightgray;
  background-color: unset;
  overflow: hidden;
  color: white;
  padding: 10px 50px;
  transition: background-color 0.1s ease-in;
  &:hover {
    background-color: rgba(255, 255, 255, 0.5);
  }
}
