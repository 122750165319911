.h-20 {
  height: 10px;
}

.center_text {
  text-align: center;
}
.outline_container {
  margin: 10px 0px;
  padding: 10px 0px;
  border-bottom: solid 1px lightgray;
  border-top: solid 1px lightgray;
}

.valid_deck {
  margin-top: 20px;
  text-decoration: underline;
  font-size: 14px;
}

.white_text {
  color: lightgray;
}
.side-bar {
  background-color: black;
  width: 260px;
  position: fixed;
  top: 0px;
  bottom: 0px;
  box-shadow: 5px 0 20px 5px rgb(229, 229, 229);
  z-index: 5;
}

.pointer {
  cursor: pointer;
}

.center_text {
  text-align: center;
  width: 100%;
}

.title {
  font-size: large;
}

.create_deck {
  min-height: 20px;
  margin: auto;
  width: 180px;
  text-align: center;
  block-size: fit-content;
  border: solid 2px lightgray;
  padding: 5px;
  border-radius: 5px;
  background-color: rgba(23, 207, 23, 0.7);

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.done_deck:hover {
  background-color: rgba(23, 207, 23, 0.9);
}

.center_input_text {
  display: flex;
  justify-content: center;
}

.white_text {
  color: lightgray;
  text-shadow: 1px 1px rgb(20, 20, 20);
}

.current_deck {
  min-height: 20px;
  margin: auto;
  width: 180px;
  text-align: center;
  block-size: fit-content;
  border: solid 2px darkgray;
  padding: 5px;
  border-radius: 5px;
  background-color: lightgray;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.delete_deck {
  min-height: 20px;
  margin: auto;
  width: 180px;
  text-align: center;
  block-size: fit-content;
  border: solid 2px lightgray;
  padding: 5px;
  border-radius: 5px;
  background-color: rgba(255, 0, 0, 0.7);

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.delete_deck:hover {
  background-color: rgba(255, 0, 0, 0.9);
}

.done_deck_container {
  width: 100%;
  position: absolute;
  bottom: 20px;
  margin: auto;
  min-height: 20px;
}
.done_deck {
  min-height: 20px;
  margin: auto;
  width: 180px;
  text-align: center;
  block-size: fit-content;
  border: solid 2px lightgray;
  padding: 5px;
  border-radius: 5px;
  background-color: rgba(23, 207, 23, 0.7);

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.done_deck:hover {
  background-color: rgba(23, 207, 23, 0.9);
}

.height_block_top {
  padding-top: 10px;
}

.checkboxDeckContainer {
  width: 100%;
  padding-left: 30px;
  margin: 10px 0px;
}
.checkboxDeck {
  margin: 5px 0px;
}

.all_decks_list {
  margin: auto;
  width: 180px;
  max-height: 180px;
  overflow-y: scroll;
  block-size: fit-content;
  border: solid 2px darkgray;
  border-radius: 5px;
  background-color: lightgray;
}

.all_decks_list_item {
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-top: 5px;
  padding-bottom: 7px;
  border-bottom: 1px solid darkgray;
}

.all_decks_list_item:hover {
  background-color: darkgray;
}
