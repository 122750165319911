.user_deck_container {
  display: flex;
  margin: 10px 25px;
  position: absolute;
  bottom: 0px;
  left: 260px;
  height: 25vh;
  align-items: center;
  justify-content: start;
  display: flex;
  flex-wrap: wrap;
  overflow-y: auto;
  gap: 20px;
}

.flex_space_between {
  display: flex;
  justify-content: space-between;
}
