.lazy-load-image-wrapper {
  margin: 0px;
  padding: 0px;
  line-height: 0;
}

.lazy-load-image {
  display: block;
  margin: 0px;
  padding: 0px;
  max-width: 100%;
  height: auto;
}

.card-loader{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 1);
    position: absolute;
    width: 100%;
    top: 0;
    bottom: 0;
}