.google-login-button {
  /* Add your custom styles here */
  padding: 5px 15px;
  border-radius: 5px !important;
  border: solid !important;
  background-color: black;
  border-color: #a1a1a1 !important;
  border-width: 2px !important;
  cursor: pointer;
  color: #ccc !important;
  text-decoration: none;
  font-size: 14px;
  display: block;
  background-color: rgba(0, 0, 0, 0.7) !important;

  div{
    background-color: rgba(0, 0, 0, 0.7) !important;
  }

  &:hover {
    color: rgb(57, 208, 184) !important;
    border-color: rgb(57, 208, 184) !important;
    background-color: rgba(0, 0, 0, 1);
    transition: all 0.15s linear !important;
  }
}