/* Set the background color and opacity of the shadow */

/* Set the position of the popup in the center of the screen */
.popup-overlay {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  inset: 0px;
  display: flex;
  z-index: 999;
  pointer-events: auto;
}

.popup-content {
  background-color: transparent !important;
  border: none;
  width: unset;
}
