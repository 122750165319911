body {
  overflow-x: hidden;
  overflow-y: hidden;
}

.body {
  background-color: black;
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
}

.user_card_catalog_container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  padding: 10px 25px;
}

.background_library {
  background-image: url("../../../assets/bloodtstor_deck_builder.jpg");
  background-repeat: repeat;
  background-position: 280px;
  background-size: contain;
}
.user_card_catalog {
  left: 260px;
  position: absolute;
  top: 0px;
  height: 70vh;
  border-bottom: 1px solid darkgray;
  overflow-y: scroll;
  overflow-x: hidden;
}
