$card_width: 95px;
$card_width-inner: 70px;

$spell_card_animation_duration: 500ms;

/*
.lift_card {
  position: relative;
  bottom: 0px;
  &:hover {
    bottom: 20px;
    transition: bottom 0.15s linear;
  }
}
*/

.card_spell {
  width: $card_width;
  cursor: pointer;
  background-color: #43ea53;
  border-radius: 2px;

  cursor: pointer;
  border: solid;
  border-color: rgb(148, 143, 87);
  border-width: 1px;

  &__cost {
    display: flex;
    margin: 5px;
    color: rgba(0, 0, 0, 1);
    justify-content: center;
  }

  &-exit {
    opacity: 1;
    position: fixed;
  }
  &-exit-active {
    opacity: 1;
    height: 120px;
    position: fixed;
    bottom: calc((100vh - 60px) / 2);
    transition: bottom $spell_card_animation_duration !important;
  }
  &-exit-done {
    opacity: 1;
  }
  &-enter {
    opacity: 1;
    position: relative;
  }
  &-enter-active {
    opacity: 1;
    position: absolute;
    transition: top 300ms !important;
    top: 0px;
  }
  &-enter-done {
    opacity: 1;
  }

  &:hover {
    background-color: #29bc37;
  }

  &__image_container {
    width: 100%;
    height: 90px;
    position: relative;
    img {
      object-fit: cover;
      max-width: 85px;
      max-height: 90px;
      height: 90px;
    }
  }
}

.card_spell_middle {
  color: black;
  position: fixed;
  left: 50%;
  height: 150px;
  width: $card_width;
  background-color: #43ea53;
  border-radius: 2px;
  border: solid;
  border-color: rgb(148, 143, 87);
  border-width: 1px;
  z-index: 1;
  top: 50%;
  transform: translate3d(-40px, -75px, 0px);

  &-exit {
    color: white;
    position: fixed;
    left: 50%;
    top: 0%;
    height: 120px;
    transform: translateY(-60px);
    width: $card_width;
    background-color: #43ea53;
    border-radius: 2px;
    border: solid;
    border-color: rgb(148, 143, 87);
    border-width: 1px;
    z-index: 1;
    transition: top 5000ms !important;
    top: 50%;
  }
  &-exit-active {
    opacity: 1;
    transition: top 5000ms !important;
    top: 50%;
  }
  &-exit-done {
    opacity: 1;
    top: 50%;
  }
  &-enter {
    opacity: 1;
    position: relative;
  }
  &-enter-active {
    opacity: 1;
    position: absolute;
    transition: top 300ms !important;
    top: 0px;
  }
  &-enter-done {
    opacity: 1;
  }

  &__image_container {
    width: 100%;
    height: 90px;
    position: relative;
    img {
      object-fit: cover;
      max-width: 85px;
      max-height: 90px;
      height: 90px;
    }
  }
}

.card_2 {
  width: $card_width;
  cursor: pointer;
  background-color: palegoldenrod;
  border-radius: 2px;

  cursor: pointer;
  border: solid;
  border-color: rgb(148, 143, 87);
  border-width: 1px;

  &:hover {
    background-color: rgb(212, 205, 125);
  }
  &__attack {
    position: absolute;
    bottom: 0px;
    left: 0px;
    margin: 5px;
    color: rgba(0, 0, 0, 1);
    display: flex;
  }
  &__cost {
    position: absolute;
    bottom: 20px;
    left: 0px;
    margin: 5px;
    color: rgba(0, 0, 0, 1);
    display: flex;
  }
  &__regen {
    position: absolute;
    bottom: 20px;
    right: 0px;
    margin: 5px;
    color: rgba(0, 0, 0, 1);
    display: flex;
  }
  &__regen_field {
    margin: 0px;
    color: rgba(0, 0, 0, 1);
    display: flex;
    justify-content: center;
  }

  &__attacked {
    width: $card_width;
    cursor: default;
    background-color: #bbb57d;
    border-radius: 2px;

    cursor: pointer;
    border: solid;
    border-color: red;
    border-width: 2px;
  }

  &__name {
    color: black;
    font-size: 10px;
    text-align: center;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  &__has_attack {
    position: absolute;
    bottom: 0px;
    left: 0px;
    margin: 5px;
    color: rgba(0, 0, 0, 0.5);
  }
  &__has_attack_life {
    position: absolute;
    bottom: 0px;
    right: 0px;
    margin: 5px;
    color: rgba(0, 0, 0, 0.5);
  }
  &__life {
    position: absolute;
    bottom: 0px;
    right: 0px;
    margin: 5px;
    color: rgba(0, 0, 0, 1);
    display: flex;
  }
  &__stat_container {
    position: relative;
    width: 100%;
    height: 100%;
  }

  &__image_container {
    width: 100%;
    height: 90px;
    position: relative;
    img {
      object-fit: cover;
      max-width: 85px;
      max-height: 90px;
      height: 90px;
    }
  }
}

.card_back {
  overflow: hidden;
  width: 85px;
  height: 100%;
  background-color: rgb(96, 91, 61);
  border-radius: 2px;

  border: solid;
  border-color: rgb(42, 41, 35);
  border-width: 2px;

  &__inner_color {
    position: relative;
    top: 5px;
    left: 8px;
    width: $card_width-inner;
    height: 90%;
    background-color: rgb(58, 56, 43);
    border-radius: 4px;
  }

  &__inner_circle {
    position: relative;
    top: 22px;
    left: 20px;
    width: 30px;
    height: 60px;
    background-color: rgb(36, 35, 25);
    border-radius: 50%;
    border: solid;
    border-color: rgb(0, 0, 0);
    border-width: 1px;
  }
  &__inner_number {
    position: relative;
    top: 20px;
    left: 0px;
    width: 30px;
    height: 60px;
    color: white;
  }
}

.cracks-layer {
  position: absolute;
  mix-blend-mode: multiply;
  background-size: contain;
  opacity: 0.7;
  width: 100%;
  height: 100%;
}

.card-content {
  transition: transform 0.3s ease-out;
  /* ... */
}
