.shop_button {
  width: 150px;
  height: 190px;
  border: solid 2px lightgray;
  padding: 10px;
  border-radius: 5px;
  background-color: black;
  cursor: pointer;
  img {
    max-width: 100%;
    max-height: 100%;
  }
  margin: 10px;
  &:hover {
    border: solid 2px darkgreen;
    transition: border-color 0.15s linear;
  }
}

.shop_title {
  color: lightgray;
  text-align: center;
  width: 100%;
}

.shop_footer {
  width: 100%;
  position: absolute;

  background-color: black;
  padding: 10px 0px;
  bottom: 0px;
}

.shop_price {
  color: lightgray;
  text-align: center;
  width: 100%;
  font-weight: bold;
}

.shop_container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  overflow-y: scroll;
  position: absolute;
  top: 0px;
  bottom: 70px;
}

.shop {
  position: absolute;
  top: 50px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background-color: black;
  width: 100%;
  background-image: url("../assets/shop.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-color: black;

  &__new_card_container {
    width: 100%;
    margin: 0px auto;
    position: absolute;
    top: 50%;
    transform: translate(0%, -50%);

    display: grid;
    grid-template-columns: repeat(5, 1fr); /* 5 columns */
    grid-auto-rows: minmax(100px, auto);
    grid-auto-flow: row dense;
    gap: 0px;

    @media (min-width: 775px) and (max-width: 1000px) {
      /* styles for screen widths between 400px and 1000px */
      grid-template-columns: repeat(4, 1fr); /* 5 columns */
      gap: 35px;
    }

    @media (min-width: 575px) and (max-width: 775px) {
      /* styles for screen widths between 400px and 1000px */
      grid-template-columns: repeat(3, 1fr); /* 5 columns */
      gap: 35px;
    }

    @media (min-width: 400px) and (max-width: 575px) {
      /* styles for screen widths between 400px and 1000px */
      grid-template-columns: repeat(2, 1fr); /* 5 columns */
      gap: 35px;
    }

    @media (max-width: 400px) {
      grid-template-columns: repeat(2, 1fr); /* 5 columns */
      gap: 35px;
      top: 45%;
    }
  }
  &__card {
    margin: auto 0px;
    color: white;
  }
  &__navbar {
    background-color: black;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__cash {
    color: lightgray;
    margin: 0px 20px;
  }
}

.shop_center_text {
  text-align: center;
  margin: 20px 0px;
}

.shop_exit {
  border: solid 2px lightgray;
}
