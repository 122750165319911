body {
  overflow-x: hidden;
  overflow-y: hidden;
}

.body {
  background-color: black;
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
}

.user_card_catalog_container {
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
  padding: 35px 50px;
}

.background_market {
  position: fixed;
  right: 0px;
}

.market_card_catalog {
  left: 260px;
  position: absolute;
  top: 0px;
  bottom: 0px;
  overflow-y: scroll;
  overflow-x: hidden;
}
