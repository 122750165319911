.dashboard {
  background-color: black;
  height: 100vh;
  height: 100svh;
  width: 100%;
  z-index: -3;
  position: absolute;
  overflow-y: hidden;
  overflow-x: hidden;

  ::-webkit-scrollbar {
    display: none;
  }

  /* Hide the scrollbar for other browsers */
  scrollbar-width: none;
  -ms-overflow-style: none;

  @media only screen and (max-width: 768px) {
    background-image: url("./../../assets/dashboard.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
}

.dashboard {
  overflow-y: scroll;
}

.dashboard_background {
  z-index: -2;
  position: fixed;
  right: 0px;
}

.elo_container {
  padding: 10px 10px;
  margin: 5px;
  border-radius: 10px;
  background-color: rgba($color: #000000, $alpha: 0.75);
  border: solid;
  border-width: 2px;
  border-color: rgb(57 208 184);
  //cursor: pointer;

  th {
    color: #ccc;
    font-size: 24px;
    font-weight: bold;
    padding: 10px;
  }
  td {
    color: #ccc;
    font-size: 20px;
    text-align: center;
    padding: 10px;
  }

  /*
  &:hover {
    transition: background-color 0.15s linear;
    border-color: rgb(57 208 184);
    background-color: rgba($color: #000000, $alpha: 1);
    transition: color 0.15s linear;
    transition: border-color 0.15s linear;
  }
  */
}
